import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';
import usersApi from '@/api/common/users';
import { debounce } from 'vue-debounce';
import { mapState } from "vuex";
import moment from "moment";
const paginate = require("paginate-array");
export default {
    extends: baseComponent,
    components: {
        detailUser: lazyLoadComponent({
            componentFactory: () => import("@/views/users/DetailUserInfo"),
            loading: SkeletonBox,
        }),
        detailUserNote: lazyLoadComponent({
            componentFactory: () => import("@/views/users/userNoteList"),
            loading: SkeletonBox,
        }),
        comments: lazyLoadComponent({
            componentFactory: () => import("@/components/Comments"),
            loading: SkeletonBox,
        }),
        userTypeView: lazyLoadComponent({
            componentFactory: () => import("@/views/users/UserListView"),
            loading: SkeletonBox,
        })
    },
    watch: {
        $route(to, from) {
            if (this.$route.query.view) {
                if (this.$route.query.view === 'low-balance') {
                    this.onFilterUserLowBalance();
                }
            }
        },
        lowBalanceUser(newCount, oldCount) {
            if (this.userLowBalance) {
                this.onFilterUserLowBalance();
            }
        }
    },
    data() {
        return {
            debounceFnc: null,
            debounceFilterFnc: null,
            viewTab: "",
            nationality: "",
            numberOfDay: 30,
            elements: {
                userSourceFilterList: [],
                queryAction: "",
                querySearch: "",
                filterItem: {},
                data: [],
                pagingItem: {
                    pageIndex: 1,
                    pageSize: 21,
                    numberOfPage: 1,
                    outRowsNumber: 0,
                    orderBy: "created_at",
                    directionSort: "desc"
                }
            },
            toogleFilter: false,
            userLowBalance: false,
            topRecharge: false,
            detailUserNote: null,
            elementDetail: null,
            viewType: "list",
            currentViewComponent: lazyLoadComponent({
                componentFactory: () => import("@/views/users/UserListView"),
                loading: SkeletonBox,
            }),
            warningMail: {
                warningMailList: [],
                pageNumber: 1,
                numItemsPerPage: 10,
            }
        };
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
            staffsInfo: state => state.staffs,
            lowBalanceUser: state => state.lowBalanceUser,
        }),
        regionList() {
            return Object.keys(this.$countryList).map(x => {
                return { id: x, text: this.$countryList[x] }
            });
        },
        defaultPagingItem() {
            return {
                pageIndex: 1,
                pageSize: 21,
                numberOfPage: 1,
                outRowsNumber: 0,
                orderBy: "created_at",
                directionSort: "desc"
            };
        },
        requestParam() {
            return {
                queryAction: this.elements.queryAction,
                querySearch: this.elements.querySearch,
                pageSize: this.elements.pagingItem.pageSize,
                filterItem: Object.assign(this.elements.filterItem, Object.assign({ appCode: this.viewTab }, {
                    userLowBalance: this.userLowBalance,
                    topRecharge: this.topRecharge,
                }))
            };
        },
        paginateParningMailList() {
            return paginate(this.warningMail.warningMailList, this.warningMail.pageNumber, this.warningMail.numItemsPerPage);
        }
    },
    created() {
        this.getUserSource();
        if (this.$route.query.view) {
            if (this.$route.query.view === 'low-balance') {
                this.onFilterUserLowBalance();
            }
            else this.getAllResult(1);
        }
        else this.getAllResult(1);
        this.debounceFnc = debounce(() => {
            this.getSearchQueryResult(1);
        }, 1000);
        this.debounceFilterFnc = debounce(() => {
            this.getFilterQueryResult(1);
        }, 1000);
    },
    beforeDestroy() {
        clearInterval(this.intervalCheckLowBalance);
    },
    methods: {
        getUserSource() {
            usersApi.getUserSource().then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.elements.userSourceFilterList = response.data.data;
                }
            }).catch(error => {
                console.error(error);
            });
        },
        switchViewType() {
            if (this.viewType === 'list') {
                this.viewType = 'grid';
                this.currentViewComponent = lazyLoadComponent({
                    componentFactory: () => import("@/views/users/UserGridView"),
                    loading: SkeletonBox,
                });
            }
            else {
                this.viewType = 'list';
                this.currentViewComponent = lazyLoadComponent({
                    componentFactory: () => import("@/views/users/UserListView"),
                    loading: SkeletonBox,
                });
            }
        },
        getElementsList(pageNumber) {
            this.showLoading();
            usersApi.getAllTop100MostUsed(pageNumber, this.requestParam).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.elements.data = response.data.data.data;
                    this.elements.pagingItem = response.data.data.pagingItem;
                    this.hideLoading();
                }
                else {
                    this.elements.data = [];
                    this.elements.pagingItem = this.defaultPagingItem;
                    this.hideLoading();
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        commonAct.showError(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.elements.data = [];
                this.elements.pagingItem = this.defaultPagingItem;
                this.hideLoading();
                commonAct.showError(error);

            });
        },
        getAllResult(pageNumber) {
            this.elements.queryAction = "";
            this.elements.filterItem = {};
            this.getElementsList(pageNumber);
        },
        getSearchQueryResult(pageNumber) {
            this.elements.queryAction = "search";
            this.elements.filterItem = {};
            this.getElementsList(pageNumber);
        },
        getFilterQueryResult(pageNumber) {
            this.elements.queryAction = "filter";
            this.getElementsList(pageNumber);
        },
        pageClickHandle(pageNumber) {
            switch (this.elements.queryAction) {
                case "filter":
                    this.getFilterQueryResult(pageNumber);
                    break;
                case "search":
                    this.getSearchQueryResult(pageNumber);
                    break;
                default:
                    this.getAllResult(pageNumber);
                    break;
            }
        },
        refreshClick() {
            this.elements.querySearch = "";
            if (this.viewTab === "" && this.nationality === "") this.getAllResult(1);
            else {
                this.elements.filterItem = {};
                this.clearExtraFilter();
                this.getFilterQueryResult(1);
            }
        },
        onSearchInput(isDebounce) {
            this.clearExtraFilter();
            if (isDebounce) {
                if (this.debounceFnc) this.debounceFnc();
            } else {
                this.getSearchQueryResult(1);
            }
        },
        onFilterInput(isDebounce) {
            if (isDebounce) {
                this.clearExtraFilter();
                if (this.debounceFilterFnc) this.debounceFilterFnc();
            } else {
                this.getFilterQueryResult(1);
            }
        },
        clearExtraFilter() {
            this.userLowBalance = false;
            this.topRecharge = false;
        },
        onFilterTopRecharge() {
            //this.viewTab = "";
            //this.nationality = "";
            this.elements.filterItem = {};
            this.topRecharge = true;//!this.topRecharge;
            if (this.topRecharge) this.userLowBalance = false;
            this.onFilterInput(false);
        },
        onFilterUserLowBalance() {
            //this.viewTab = "";
            //this.nationality = "";
            this.elements.filterItem = {};
            this.userLowBalance = true;//!this.userLowBalance;
            if (this.userLowBalance) this.topRecharge = false;
            this.onFilterInput(false);
        },
        onFilterTodayRegister() {
            //this.toogleFilter = true;
            //this.viewTab = "";
            this.clearExtraFilter();
            let startDate = moment().startOf('day').format('DD/MM/YYYY');
            let endDate = moment().startOf('day').add(1, 'days').format('DD/MM/YYYY');
            this.elements.filterItem.createdFilter = [startDate, endDate];
            this.getFilterQueryResult(1);
        },
        viewDetailInfo(userItem) {
            this.$set(this, "elementDetail", userItem);
            this.$nextTick(() => {
                $("#detailUserInfo").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        closeDetailInfo() {
            $("#detailUserInfo").modal("hide");
            this.$set(this, "elementDetail", null);
        },
        viewDetailNote(userItem) {
            this.$set(this, "detailUserNote", userItem);
            this.$nextTick(() => {
                $("#detailUserNote").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        editDetailNote(userItem) {
            this.$set(this, "detailUserNote", userItem);
            this.$nextTick(() => {
                $("#detailUserNote").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        closeDetailNote() {
            $("#detailUserNote").modal("hide");
            this.$set(this, "detailUserNote", null);
        },
        addToWarningMailList(itemTarget) {
            let findIndex = this.warningMail.warningMailList.findIndex(x => x.id === itemTarget.id);
            if (findIndex === -1) {
                this.warningMail.warningMailList.push(JSON.parse(JSON.stringify(itemTarget)));
            }
        },
        removeToWarningMailList(itemTarget) {
            let findIndex = this.warningMail.warningMailList.findIndex(x => x.id === itemTarget.id);
            if (findIndex !== -1) {
                this.warningMail.warningMailList.splice(itemTarget, 1);
            }
        },
        viewWarningIdleList() {
            this.$nextTick(() => {
                $("#mailWarningList").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        sendWarningMailIdleList() {
            commonAct.showConfirm(`Send email warning idle for long time to selected users ? This cannot be undone`, () => {
                let emailList = this.warningMail.warningMailList.map(x => x.email);
                usersApi.sendWarningUserIdle(emailList).then(response => {
                    if (response.data && response.data.result === 0) {
                        this.$set(this.warningMail, 'warningMailList', []);
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            commonAct.showError(response.data.message || this.$lang.common.error);
                        }
                    }
                }).catch(error => {
                    console.error(error);
                    commonAct.showError(error);
                });
            });
        },
        newestCommentUpdated(noteContent) {

        },
        performActions(data) {
            switch (data.actionName) {
                case 'lockUser':
                    this.deactiveUserToggle(data.actionData);
                    break;
                case 'viewDetailNote':
                    this.viewDetailNote(data.actionData);
                    break;
                case 'addToWarningMailList':
                    this.addToWarningMailList(data.actionData);
                    break;
            }
        },
        exportData() {
            let host = process.env.VUE_APP_API_HOST.slice(0, -1);
            window.open(host + usersApi.exportListUserIdle(1, this.requestParam, this.numberOfDay), '_blank');
        },
        deactiveUserToggle(targetItem) {
            commonAct.showConfirm(`Lock user <strong>${targetItem.fullName}</strong> ?`, () => {
                let request = {
                    isDeactive: targetItem.status === 1,
                    userId: targetItem.id,
                    deviceInformation: JSON.stringify(this.getInformationClient()),
                }
                usersApi.deactiveUser(request).then(response => {
                    if (response.data && response.data.result === 0) {
                        this.showSuccessToast("User info has been updated");
                        let foundedItemIndex = this.elements.data.findIndex(x => { return x.id === targetItem.id });
                        if (foundedItemIndex > -1) {
                            this.elements.data.splice(foundedItemIndex, 1);
                            this.getSearchQueryResult(this.elements.pagingItem.pageIndex);
                        }

                    }
                    else {
                        if (response.data.message !== null && response.data.message !== '') {
                            commonAct.showError(response.data.message || this.$lang.common.error);
                        }
                    }
                }).catch(error => {
                    console.error(error);
                    commonAct.showError(error);
                });
            });
        },
        removeUser(targetItem) {
            commonAct.showConfirm(`Remove user <strong>${targetItem.fullName}</strong> ? This cannot be undone`, () => {
                this.showLoading();
                usersApi.removeUser(targetItem.id).then(response => {
                    if (response.data.result === 0) {
                        let foundedItemIndex = this.elements.data.findIndex(x => { return x.id === targetItem.id });
                        if (foundedItemIndex > -1) {
                            this.elements.data.splice(foundedItemIndex, 1);
                            this.getSearchQueryResult(this.elements.pagingItem.pageIndex);
                        }
                    } else {
                        commonAct.showError(response.data.message);
                    }
                    this.hideLoading();
                }).catch(error => {
                    console.error(error);
                    commonAct.showError(error);
                    this.hideLoading();
                });
            });
        }
    }
}